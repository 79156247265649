import {
  Box,
  ListItemIcon,
  ListItemText,
  Tooltip as MUITooltip,
  Typography,
  useTheme,
} from "@mui/material";
import MUIMenuItem from "@mui/material/MenuItem";
import { FC, PropsWithChildren } from "react";
import { Link } from "react-router-dom";

import { MenuEntryInterface } from "../../types/menu-type";

// depending on whether or not a link is set, we need to either use a `Link` root element for the menu item or no root element at all.
// this FC takes care of that.
const RootElement: FC<
  PropsWithChildren<{
    link?: string;
  }>
> = ({ children, link }) =>
  link ? (
    <Link to={link} style={{ textDecoration: "none" }}>
      {children}
    </Link>
  ) : (
    children
  );

const Tooltip: FC<PropsWithChildren<{ level: number; title: string }>> = ({
  children,
  level,
  title,
}) => {
  const theme = useTheme();

  return level > 0 ? (
    <MUITooltip
      componentsProps={{
        tooltip: {
          sx: {
            "& .MuiTooltip-arrow": {
              color: "white",
            },
            color: theme.palette.text.primary,
            p: 2,
            fontWeight: 400,
            fontSize: "1rem",
            backgroundColor: "background.paper",
            padding: 1,
            borderRadius: "6px",
            border: "1px solid",
            borderColor: theme.palette.secondary.dark,
            whiteSpace: "pre-wrap",
          },
        },
      }}
      disableInteractive
      placement="right-end"
      title={title}
    >
      {children as JSX.Element}
    </MUITooltip>
  ) : (
    children
  );
};

export const MenuItem: FC<MenuEntryInterface> = ({
  title,
  active,
  link,
  icon: ItemIcon,
  level,
}) => {
  const theme = useTheme();

  return (
    <RootElement link={link}>
      <Tooltip level={level} title={title}>
        <MUIMenuItem
          selected={active}
          data-testid="MenuItem"
          sx={[
            {
              color: active
                ? theme.palette.primary.main
                : theme.palette.grey["800"],
              marginLeft: level > 0 ? "26px" : "0px",
              padding: level > 0 ? "6px 16px 6px 10px" : "6px 16px",
              "& .MuiListItem-root": {
                display: "list-item",
              },
              "&.Mui-selected": {
                background: "none",
              },
            },
          ]}
        >
          {ItemIcon &&
            (typeof ItemIcon === "string" ? (
              <Box
                component="img"
                src={ItemIcon}
                sx={{
                  minWidth: "20px!important",
                  mr: (level || 0) > 0 ? 0 : "10px",
                }}
              />
            ) : (
              <ListItemIcon
                sx={{
                  minWidth: "20px!important",
                  mr: (level || 0) > 0 ? 0 : "10px",
                }}
              >
                <ItemIcon
                  sx={{
                    fontSize: (level || 0) > 0 ? ".3rem" : "1.8rem",
                    color: active
                      ? theme.palette.primary.main
                      : theme.palette.grey["500"],
                  }}
                />
              </ListItemIcon>
            ))}

          <ListItemText
            sx={{
              display: (level || 0) > 0 ? "list-item" : "",
              ml: (level || 0) > 0 ? "15px" : 0,
            }}
          >
            <Typography
              sx={{
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                fontWeight: active ? 500 : undefined,
              }}
            >
              {title}
            </Typography>
          </ListItemText>
        </MUIMenuItem>
      </Tooltip>
    </RootElement>
  );
};
