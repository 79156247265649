import { InfoOutlined } from "@mui/icons-material";
import {
  Alert,
  Box,
  ClickAwayListener,
  IconButton,
  Popper,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import {
  availableStatusColors,
  CheckBoxField,
  DateFormField,
  FormField,
  FormFields,
  FormRow,
  FormRowHeader,
  IdentifierBadge,
  InputFormField,
  Markdown,
  ProgressFormField,
  SelectFormField,
  SingleRadioStatusIndicatorFormField,
  TextFormField,
} from "@pimo/pimo-components";
import {
  ACTIVITY_STATUS_VALUES,
  ActivityAttributes,
  SOLUTION_TYPES,
  SolutionType,
} from "dlp-types";
import { FC, useState } from "react";
import React from "react";
import { useFormContext } from "react-hook-form";

interface ActivityFormRowProps {
  /** the activity object */
  activity: ActivityAttributes;
  /** index number */
  index: number;
  activitiesIndex: number;
}

export const ActivityFormRow: FC<ActivityFormRowProps> = ({
  activity,
  index,
  activitiesIndex,
}) => {
  const { setValue, getValues } = useFormContext();
  const theme = useTheme();
  const [solutionType, setSolutionType] = useState<SolutionType | undefined>(
    activity.solutionType
  );
  const [hasRisk, setHasRisk] = useState<boolean | undefined>(activity.hasRisk);
  const [showError, setShowError] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [isStatusCompleted, setIsStatusCompleted] = useState(
    activity.status === "completed"
  );
  const [comment, setComment] = useState<string | undefined>(
    getValues(
      `activitiesGroupedBySubdimension.${activitiesIndex}.activities.${index}.comment`
    ) as string
  );

  function markActivityAsEdited(activitiesIndex: number, index: number) {
    setValue(
      `activitiesGroupedBySubdimension.${activitiesIndex}.activities.${index}.isEdited`,
      true
    );
  }
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [openPopup, setOpen] = React.useState(false);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const clickAwayHandler = () => {
    setOpen(false);
  };
  return (
    <FormRow data-testid="activityFormRow">
      <FormRowHeader
        sx={{
          justifyContent: "space-between",
          width: "100%",
          alignContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: 1,
            alignContent: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          {openPopup && (
            <ClickAwayListener
              onClickAway={clickAwayHandler}
              mouseEvent={"onMouseUp"}
            >
              <Popper
                placement="top-start"
                disablePortal={true}
                open={openPopup}
                anchorEl={anchorEl}
                sx={{
                  border: "1px solid",
                  borderColor: "gray",
                  borderRadius: "12px",
                  backgroundColor: "white",
                  boxShadow: "1px 1px 5px",
                  p: 1,
                  zIndex: 10000,
                }}
              >
                <Typography sx={{ px: 1, maxWidth: 1000, fontStyle: "italic" }}>
                  <Markdown>{activity.objective ?? ""}</Markdown>
                </Typography>
              </Popper>
            </ClickAwayListener>
          )}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 1,
              lineHeight: "1.5rem",
              alignItems: "center",
            }}
          >
            <IdentifierBadge>{activity.activityId ?? ""}</IdentifierBadge>
            <Typography>{activity.description}</Typography>
          </Box>
          {activity.objective && (
            <IconButton onClick={handleClick}>
              <InfoOutlined />
            </IconButton>
          )}
        </Box>
        {showError && (
          <Alert variant="filled" severity="error">
            Progress 100 should always be associated with status "completed"
          </Alert>
        )}
        {showWarning && (
          <Alert variant="filled" severity="warning">
            Status is "At Risk". Please provide a comment to describe the risk
            and mitigation actions.
          </Alert>
        )}
      </FormRowHeader>
      <FormFields>
        <FormField label={"Activity Progress"}>
          <ProgressFormField
            name={
              activity.progressFromIam
                ? `activitiesGroupedBySubdimension.${activitiesIndex}.activities.${index}.progressFromIam`
                : `activitiesGroupedBySubdimension.${activitiesIndex}.activities.${index}.progress`
            }
            isDisabled={activity.isEditDisabled}
            onValueChange={(value) => {
              markActivityAsEdited(activitiesIndex, index);
              if (activity.isEditDisabled) {
                return;
              }
              /**
               * if progress new value is 100 then set status to completed, but if progress new value is not 100
               * and status is completed show an error
               */
              switch (value) {
                case 100:
                  setValue(
                    `activitiesGroupedBySubdimension.${activitiesIndex}.activities.${index}.status`,
                    "completed"
                  );
                  setIsStatusCompleted(true);
                  break;
                default:
                  getValues(
                    `activitiesGroupedBySubdimension.${activitiesIndex}.activities.${index}.status`
                  ) === "completed"
                    ? setShowError(true)
                    : setShowError(false);
                  setIsStatusCompleted(false);
              }
            }}
          />
        </FormField>
        <FormField label={"Status"}>
          <SingleRadioStatusIndicatorFormField
            name={
              activity.statusFromIam
                ? `activitiesGroupedBySubdimension.${activitiesIndex}.activities.${index}.statusFromIam`
                : `activitiesGroupedBySubdimension.${activitiesIndex}.activities.${index}.status`
            }
            values={[
              {
                value: ACTIVITY_STATUS_VALUES[0],
                label: ACTIVITY_STATUS_VALUES[0],
                color: "lightgray",
              },
              {
                value: ACTIVITY_STATUS_VALUES[1],
                label: ACTIVITY_STATUS_VALUES[1],
                color: availableStatusColors.GREEN,
              },
              {
                value: ACTIVITY_STATUS_VALUES[2],
                label: ACTIVITY_STATUS_VALUES[2],
                color: availableStatusColors.YELLOW,
              },
              {
                value: ACTIVITY_STATUS_VALUES[3],
                label: ACTIVITY_STATUS_VALUES[3],
                color: theme.palette.primary.main,
              },
            ]}
            radioProps={{ disabled: activity.isEditDisabled }}
            handleChange={(value) => {
              markActivityAsEdited(activitiesIndex, index);
              if (activity.isEditDisabled) {
                return;
              }
              /**
               * if status new value is completed then set progress to 100, but if status new value is not completed
               * and progress is 100 show an error
               */
              value === "completed" &&
                setValue(
                  `activitiesGroupedBySubdimension.${activitiesIndex}.activities.${index}.progress`,
                  100
                );
              value === "completed" && setIsStatusCompleted(true);

              value !== "completed" &&
              getValues(
                `activitiesGroupedBySubdimension.${activitiesIndex}.activities.${index}.progress`
              ) === 100
                ? setShowError(true)
                : setShowError(false);
              value !== "completed" && setIsStatusCompleted(false);

              value === "at risk"
                ? setShowWarning(true)
                : setShowWarning(false);
            }}
          />
        </FormField>
        {!isStatusCompleted && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              gap: 1,
            }}
          >
            <SelectFormField
              label={"Project Plan"}
              name={`activitiesGroupedBySubdimension.${activitiesIndex}.activities.${index}.projectPlan`}
              options={["yes", "no"].map((type) => ({
                value: type,
                label: type,
              }))}
              disabled={activity.isEditDisabled}
              onValueChange={() => {
                markActivityAsEdited(activitiesIndex, index);
              }}
            />
            <InputFormField
              label={"Allocated Budget"}
              name={`activitiesGroupedBySubdimension.${activitiesIndex}.activities.${index}.allocatedBudget`}
              isNumeric
              disabled={activity.isEditDisabled}
              onValueChange={() => {
                markActivityAsEdited(activitiesIndex, index);
              }}
            />
            <DateFormField
              isDisabled={activity.isEditDisabled}
              onValueChange={() => markActivityAsEdited(activitiesIndex, index)}
              label={"Planned Completion Date"}
              name={`activitiesGroupedBySubdimension.${activitiesIndex}.activities.${index}.completionDate`}
            />
          </Box>
        )}
        <TextFormField
          onValueChange={(val) => {
            markActivityAsEdited(activitiesIndex, index);
            setComment(val);
          }}
          isDisabled={activity.isEditDisabled}
          label={
            comment
              ? "Comment on Status"
              : "Please provide a comment on the project status"
          }
          name={`activitiesGroupedBySubdimension.${activitiesIndex}.activities.${index}.comment`}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            gap: 1,
          }}
        >
          {activity.dimension === "Technology" && (
            <SelectFormField
              label={"Solution Type"}
              name={`activitiesGroupedBySubdimension.${activitiesIndex}.activities.${index}.solutionType`}
              options={Object.values([...SOLUTION_TYPES]).map(
                (criticality) => ({
                  value: criticality,
                  label: criticality,
                })
              )}
              disabled={activity.isEditDisabled}
              onValueChange={(val) => {
                setSolutionType(
                  val as "Target" | "Local" | "Local & Target" | undefined
                );
                markActivityAsEdited(activitiesIndex, index);
              }}
            />
          )}
          {activity.dimension === "Technology" &&
            (solutionType === "Local" || solutionType === "Local & Target") && (
              <InputFormField
                onValueChange={() =>
                  markActivityAsEdited(activitiesIndex, index)
                }
                disabled={activity.isEditDisabled}
                label={"Name of Local Solution"}
                name={`activitiesGroupedBySubdimension.${activitiesIndex}.activities.${index}.localSolutionName`}
              />
            )}
          {activity.dimension === "Technology" &&
            (solutionType === "Target" ||
              solutionType === "Local & Target") && (
              <Tooltip title={activity.targetSolution}>
                <span>
                  <InputFormField
                    onValueChange={() =>
                      markActivityAsEdited(activitiesIndex, index)
                    }
                    label={"Name of Target Solution"}
                    disabled={true}
                    staticValue={activity.targetSolution}
                    name={``}
                  />
                </span>
              </Tooltip>
            )}
        </Box>
        {activity.dimension === "Technology" && (
          <TextFormField
            isDisabled={activity.isEditDisabled}
            onValueChange={() => markActivityAsEdited(activitiesIndex, index)}
            label={"Reason for Solution"}
            name={`activitiesGroupedBySubdimension.${activitiesIndex}.activities.${index}.solutionReason`}
          />
        )}

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            width: 250,
          }}
        >
          <CheckBoxField
            onChange={(val) => {
              setHasRisk(val);
              markActivityAsEdited(activitiesIndex, index);
            }}
            isDisabled={activity.isEditDisabled}
            label={"with Risk"}
            name={`activitiesGroupedBySubdimension.${activitiesIndex}.activities.${index}.hasRisk`}
          />

          {hasRisk && (
            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
              <TextFormField
                onValueChange={() =>
                  markActivityAsEdited(activitiesIndex, index)
                }
                rows={3}
                multiline
                isDisabled={activity.isEditDisabled}
                label={"Risk Comment"}
                name={`activitiesGroupedBySubdimension.${activitiesIndex}.activities.${index}.riskComment`}
              />
              <TextFormField
                onValueChange={() =>
                  markActivityAsEdited(activitiesIndex, index)
                }
                rows={1}
                isDisabled={activity.isEditDisabled}
                label={"Risk Id"}
                name={`activitiesGroupedBySubdimension.${activitiesIndex}.activities.${index}.riskId`}
              />
            </Box>
          )}
        </Box>
      </FormFields>
    </FormRow>
  );
};
